<template>
  <div class="coupon-search-list">
    <div class="search-box">
      <van-search
        v-model="keywords"
        placeholder="输入关键词/商品名称"
        show-action
        @click="onCancel"
        @search="search"
        @cancel="onCancel"
        @clear="search"
      />
    </div>
    <div class="coupon-box">
      <coupon-list
        :list="couponList"
        v-if="couponList.length > 0"
        @getList="search"
      ></coupon-list>
      <div class="no-list" v-else>抱歉，当前暂无卡券</div>
    </div>
  </div>
</template>

<script>
import { Search } from "vant";
import CouponList from "@/components/CouponList.vue";
import { getCouponListBySearch } from "@/api/common";
export default {
  name: "CouponSearchList",
  components: {
    CouponList,
    [Search.name]: Search,
  },
  data() {
    return {
      keywords: "",
      couponList: [],
    };
  },
  created() {
    document.title = "搜索";
    this.keywords = this.$route.params.keywords || "";
    this.$loading("加载中...");
  },
  mounted() {
    this.search();
  },
  methods: {
    async search() {
      try {
        const res = await getCouponListBySearch({
          SearchKey: this.keywords,
        });
        this.$toast.clear();
        if (res && res.code == 0) {
          this.couponList = res.data.list || [];
        } else {
          this.$toast(res.message);
        }
      } catch (error) {
        this.$toast.clear();
      }
    },

    onCancel() {
      this.$router.go(-1)
      // this.$router.push(`/mall/searchCoupon?keywords=${this.keywords || ""}`);
      // this.$router.push({
      //   name: "searchCoupon",
      //   params: {
      //     keywords: this.keywords,
      //   },
      // });
    },
  },
};
</script>

<style lang="less" scoped>
.coupon-search-list {
  height: 100%;
  display: flex;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .search-box {
    // height: 0.6rem;
  }

  .coupon-box {
    flex: 1;
    overflow-y: auto;

    .no-list {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.2rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #bdbdbd;
    }
  }
}
</style>